import shopifyImpactGenericDefaults from '../shopify-impact-generic/config.js';

const wheelsFields = shopifyImpactGenericDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifyImpactGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

shopifyImpactGenericDefaults.Widgets.find((w) => w.name === 'Facets').initCollapsed = true;
shopifyImpactGenericDefaults.Widgets.map((w) => {
  if (w.name === 'FilterChips' || w.name === 'Facets' || w.name === 'FacetDialog') {
    w.ignoreFields.push('Fitment');
  }

  if (w.name === 'Facets') {
    w.ignoreFields.push('FitmentOption');
  }

  return w;
});

const handleStickyClass = (element) => {
  const posTop = element.getBoundingClientRect().top;

  if (posTop <= 0) {
    element.classList.add('cm_sticky');
  } else if (posTop >= 0) {
    element.classList.remove('cm_sticky');
  }
};

const baseUpdateCallback = shopifyImpactGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
)?.updateCallback;

function updateCallback() {
  baseUpdateCallback?.();

  if (window.innerWidth <= 1000) {
    const searchHeader = window.document.querySelector('.cm_SearchHeader');

    if (searchHeader) {
      handleStickyClass(searchHeader);

      window.addEventListener('scroll', () => {
        handleStickyClass(searchHeader);
      });
    }
  }
}

export default {
  includes: ['shopify-impact-generic'],
  ...shopifyImpactGenericDefaults,
  facets: {
    ...shopifyImpactGenericDefaults.facets,
    rangedFacet: [
      ...shopifyImpactGenericDefaults.facets.rangedFacet,
      { fields: ['wheel_load', 'tire_max_load'], name: 'sliderFacet' },
    ],
  },
  Widgets: [
    ...shopifyImpactGenericDefaults.Widgets.filter(
      (w) => !['HeaderVehicleWidget', 'SearchResult'].includes(w.name),
    ),
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
    {
      name: 'HomeVehicleWidget_tab',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 800,
    },
    {
      name: 'WheelPanel_tab',
      type: 'RequestPanel',
      location: '#cm-wheel-tab',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: wheelsFields,
    },
    {
      name: 'TirePanel_tab',
      type: 'RequestPanel',
      location: '#cm-tire-tab',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: tiresFields,
    },
    {
      name: 'FitmentOptionFacet',
      type: 'FacetPanel',
      fields: ['FitmentOption'],
    },
  ],
};
