
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide  cm_searchBoxDialogButton' }, _createElement('svg', {
                'role': 'presentation',
                'strokeWidth': '2',
                'focusable': 'false',
                'width': '22',
                'height': '22',
                'className': 'icon icon-search',
                'viewBox': '0 0 22 22'
            }, _createElement('circle', {
                'cx': '11',
                'cy': '10',
                'r': '7',
                'fill': 'none',
                'stroke': 'currentColor'
            }), _createElement('path', {
                'd': 'm16 15 3 3',
                'stroke': 'currentColor',
                'strokeLinecap': 'round',
                'strokeLinejoin': 'round'
            })));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]