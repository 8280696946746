import shopifyImpactGenericDefaults from '../shopify-impact-generic/config.js';

const wheelsFields = shopifyImpactGenericDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifyImpactGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

shopifyImpactGenericDefaults.Widgets.find((w) => w.name === 'Facets').initCollapsed = true;
shopifyImpactGenericDefaults.Widgets.map((w) => {
  if (w.name === 'FilterChips' || w.name === 'Facets' || w.name === 'FacetDialog') {
    w.ignoreFields.push('Fitment');
  }

  if (w.name === 'Facets') {
    w.ignoreFields.push('FitmentOption');
  }

  return w;
});

globalThis.Convermax.mountedPackagesAddToCartWithRedirect = (productId, variantId, type) => async (e) => {
  e.preventDefault();

  const redirectPath =
    type === 'Wheel'
      ? `/collections/tires/?WheelProductID/${productId}/${window.Convermax.getVehicleSearchParams()}`
      : null;

  if (redirectPath) {
    const response = await ajaxAddToCart(variantId);

    if (response.ok) {
      window.location.replace(redirectPath);
    } else {
      console.error(response);
    }
  }
};

async function ajaxAddToCart(id, quantity = 4) {
  const formData = {
    items: [
      {
        id,
        quantity,
      },
    ],
  };

  return fetch(`${window.Shopify.routes.root}cart/add.js`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  });
}

const handleStickyClass = (element) => {
  const posTop = element.getBoundingClientRect().top;

  if (posTop <= 0) {
    element.classList.add('cm_sticky');
  } else if (posTop >= 0) {
    element.classList.remove('cm_sticky');
  }
};

const baseUpdateCallback = shopifyImpactGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
)?.updateCallback;

function updateCallback() {
  baseUpdateCallback?.();

  if (window.innerWidth <= 1000) {
    const searchHeader = window.document.querySelector('.cm_SearchHeader');

    if (searchHeader) {
      handleStickyClass(searchHeader);

      window.addEventListener('scroll', () => {
        handleStickyClass(searchHeader);
      });
    }
  }
}

const onVerifyFitmentResponseReceived = (response) => {
  const mountedPackageButton = window.document.querySelector('.button-wheel-package');

  if (mountedPackageButton) {
    const fits = response.Items?.[0]?.fitsTheVehicle;

    if (fits && fits === 'yes') {
      mountedPackageButton.style.display = 'block';
    } else {
      mountedPackageButton.style.display = 'none';
    }
  }

  return response;
};

export default {
  includes: ['shopify-impact-generic'],
  ...shopifyImpactGenericDefaults,
  fitmentSearch: {
    ...shopifyImpactGenericDefaults.fitmentSearch,
    onVerifyFitmentInitResponseReceived: onVerifyFitmentResponseReceived,
    onVerifyFitmentResponseReceived,
  },
  facets: {
    ...shopifyImpactGenericDefaults.facets,
    rangedFacet: [
      ...shopifyImpactGenericDefaults.facets.rangedFacet,
      {
        fields: ['wheel_load', 'tire_max_load', 'tire_max_rim_width', 'tire_min_rim_width'],
        name: 'sliderFacet',
      },
    ],
  },
  Widgets: [
    ...shopifyImpactGenericDefaults.Widgets.filter(
      (w) => !['HeaderVehicleWidget', 'SearchResult'].includes(w.name),
    ),
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
    {
      name: 'HomeVehicleWidget_tab',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 800,
    },
    {
      name: 'WheelPanel_tab',
      type: 'RequestPanel',
      location: '#cm-wheel-tab',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: wheelsFields,
    },
    {
      name: 'TirePanel_tab',
      type: 'RequestPanel',
      location: '#cm-tire-tab',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: tiresFields,
    },
    {
      name: 'FitmentOptionFacet',
      type: 'FacetPanel',
      fields: ['FitmentOption'],
    },
  ],
};
