
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatI2(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                return _createElement('product-card', {
                    'handle': this.handle,
                    'class': 'product-card product-card--blends bg-custom text-custom' + (this.image2 ? ' product-card--show-secondary-media' : '')
                }, _createElement('div', { 'className': 'product-card__badge-list' }, this.out_of_stock ? _createElement('sold-out-badge', {
                    'key': '225',
                    'class': 'badge badge--sold-out'
                }, 'Sold out') : null, this.on_sale ? _createElement('on-sale-badge', {
                    'discount-mode': 'saving',
                    'key': '327',
                    'class': 'badge badge--on-sale'
                }, '\n      Save ', this.formatPrice(this.compare_at_price - this.price), '\n    ') : null), _createElement('div', { 'className': 'product-card__figure' }, _createElement('a', { 'href': this.url }, _createElement('img', {
                    'className': 'product-card__image product-card__image--primary aspect-natural',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError,
                    'loading': 'lazy',
                    'width': '500',
                    'height': '500'
                }), this.image2 ? _createElement('img', {
                    'className': 'product-card__image product-card__image--secondary object-fill',
                    'src': this.imageOrDefault(this.resizeImage(this.image2)),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2'),
                    'loading': 'lazy',
                    'width': '500',
                    'height': '500',
                    'fetchpriority': 'low',
                    'key': '832'
                }) : null)), _createElement('div', { 'className': 'product-card__info' }, _createElement('a', mergeProps({
                    'href': this.vendor_url,
                    'className': 'text-xs link-faded'
                }, { dangerouslySetInnerHTML: { __html: this.vendor } })), _createElement('div', { 'className': 'v-stack gap-0.5 w-full' }, _createElement('span', { 'className': 'product-card__title' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'bold'
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), !this.on_sale ? _createElement('price-list', {
                    'key': '1530',
                    'class': 'price-list'
                }, _createElement('sale-price', { 'class': 'text-subdued' }, _createElement('span', { 'className': 'sr-only cm_price' }, 'Sale price'), this.formatPrice(this.price), '\n        ')) : null, this.on_sale ? _createElement('price-list', {
                    'key': '1764',
                    'class': 'price-list'
                }, _createElement('sale-price', { 'class': 'text-on-sale' }, _createElement('span', { 'className': 'sr-only cm_price' }, 'Sale price'), this.formatPrice(this.price), '\n        '), _createElement('compare-at-price', { 'class': 'text-subdued line-through' }, _createElement('span', { 'className': 'sr-only cm_price' }, 'Regular price'), this.formatPrice(this.compare_at_price), '\n        ')) : null)), [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '22050'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI2.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null], this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '2605'
                }) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'h2 cm_related-products-title' }, 'You might also like')), _createElement.apply(this, [
        'div',
        { 'className': 'product-list cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []